<template>
  <BOverlay
    :show="configs.loading"
    spinner-variant="primary"
    variant="#000000"
  >
    <form-wizard
      ref="refFormWizard"
      color="#26a5ea"
      :start-index="configs.currentStep"
      :title="null"
      :subtitle="null"
      shape="square"
      step-size="xs"
      back-button-icon="EditIcon"
      next-button-text="Siguiente"
      back-button-text="Anterior"
      finish-button-text="Guardar Matrícula"
    > 
      
      <tab-content title="Estudiante"
        :before-change="validaEstudiante"
      >
        <TabEstudiante
          crud="c"
          ref="tabEstudiante"
          :matricula.sync="dataForm"
        />
      </tab-content>

      <tab-content title="Apoderado">
        <TabApoderado 
          crud="c" 
          ref="tabApoderado"
          :matricula.sync="dataForm" 
        />
      </tab-content>

      <!-- Footer -->
      <template #footer="{ activeTabIndex, nextTab, prevTab, nextDisabled, prevDisabled, isLastStep }">
        <div class="d-flex justify-content-between pt-50 mb-25">
          <BFormCheckbox
            id="chkCrearOtro"
            v-model="configs.chkCrearOtro"
            class="mr-auto ml-0 mt-75"
          >
            <small>Crear otra matrícula</small>
          </BFormCheckbox>
          <BButton
            v-if="activeTabIndex > 0" 
            variant="outline-secondary"
            class="ml-50"
            :disabled="prevDisabled"
            @click="prevTab"
          >
            Anterior
          </BButton>
          <BButton
            v-if="!isLastStep"
            variant="primary"
            class="ml-50 mr-0"
            :disabled="nextDisabled"
            @click="nextTab"
          >
            Siguiente
          </BButton>

          <BButton
            v-if="isLastStep"
            variant="primary"
            class="ml-50 mr-0"
            @click="validaApoderado"
          >
            Crear Matrícula
          </BButton>
        </div>
      </template>

    </form-wizard>
  </BOverlay>
</template>

<script>
import { BModal, BFormCheckbox, BButton, BOverlay, VBModal, BCard } from 'bootstrap-vue';
import { mapActions, mapMutations } from 'vuex'
import { rut } from '@/mixins/rut';
import { formatos } from '@/mixins/formatos';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import TabEstudiante from './MatriculasFormTabs/TabEstudiante.vue';
import TabApoderado from './MatriculasFormTabs/TabApoderado.vue';

export default {
  components: {
    BModal, BFormCheckbox, BButton, BOverlay, VBModal, BCard,
    FormWizard,
    TabContent,
    
    TabEstudiante,
    TabApoderado,
  },
  data() {
    return {
      configs: {
        loading: false,
        currentStep: 0,
        chkCrearOtro: false,
      },
      dataForm: {
        estudiante: {
          tipo_identificacion: 1,
          rut: '',
          nombre: '',
          segundo_nombre: '',
          primer_apellido: '',
          segundo_apellido: '',
          genero: null,
          fecha_nacimiento: '',
          correo: '',
          celular: '',
          id_comuna: null,
          nombre_calle: '',
          numero: null,
          fecha_ingreso: this.formatoFechaHoyDiaMesAno(),
          id_pais: 45,
          id_cursos: null,
          procedencia: 27,
          repitencia: false,
          prioritario: false,
          beneficio: false,
          pie: false,
          religion: false,
          junaeb: false,
          autorizacion: false,
          aprendizaje: false,
          transporte: false,
        },
        apoderado: {
          rut: '',
          nombre: '',
          segundo_nombre: '',
          primer_apellido: '',
          segundo_apellido: '',
          correo: '',
          celular: '',
          genero: null,
          fecha_nacimiento: '',
          nombre_calle: '',
          numero: '',
          id_pais: 45,
          id_comuna: null,
        },
      },

      dataFormReset: {
        estudiante: {
          tipo_identificacion: 1,
          rut: '',
          nombre: '',
          segundo_nombre: '',
          primer_apellido: '',
          segundo_apellido: '',
          genero: null,
          fecha_nacimiento: '',
          correo: '',
          celular: '',
          id_comuna: null,
          nombre_calle: '',
          numero: null,
          fecha_ingreso: this.formatoFechaHoyDiaMesAno(),
          id_pais: 45,
          id_cursos: null,
          procedencia: 27,
          repitencia: false,
          prioritario: false,
          beneficio: false,
          pie: false,
          religion: false,
          junaeb: false,
          autorizacion: false,
          aprendizaje: false,
          transporte: false,
        },
        apoderado: {
          rut: '',
          nombre: '',
          segundo_nombre: '',
          primer_apellido: '',
          segundo_apellido: '',
          correo: '',
          celular: '',
          genero: null,
          fecha_nacimiento: '',
          nombre_calle: '',
          numero: '',
          id_comuna: null,
          id_pais: 45,
        },
      },
    };
  },
  mixins: [rut, formatos],
  methods: {
    ...mapActions({
      fetchMatricula: 'matriculas/fetchMatricula',
      createMatricula: 'matriculas/addMatricula',
    }),
    ...mapMutations('matriculas', ['setMatricula']),
    async onSubmit() {
      this.configs.loading = true
      
      const apoderado = this.$refs.tabApoderado.getApoderado()
      const estudiante = this.$refs.tabEstudiante.getEstudiante()
            
      const params_apoderado = {
        rut_apoderado: apoderado.rut,
        dv_apoderado: apoderado.dv,
        nombre_apoderado: apoderado.nombre,
        segundo_nombre_apoderado: apoderado.segundo_nombre,
        primer_apellido_apoderado: apoderado.primer_apellido,
        segundo_apellido_apoderado: apoderado.segundo_apellido,
        nombre_calle_apoderado: apoderado.nombre_calle,
        numero_calle_apoderado: apoderado.numero,
        celular_apoderado: apoderado.celular,
        correo_apoderado: apoderado.correo,
        id_comuna_apoderado: apoderado.id_comuna,
        id_pais_apoderado: 45,
        genero_apoderado: apoderado.genero,
        fecha_nacimiento_apoderado: apoderado.fecha_nacimiento,
      }
      
      const response = await this.createMatricula({ ...estudiante, ...params_apoderado })
      if (response.status === 'success') {
        const matricula = await this.fetchMatricula(response.id)
        const cancelButtonText = this.configs.chkCrearOtro 
          ? 'Crear una nueva matrícula' 
          : 'Cerrar';
        const cancelButtonClass = this.configs.chkCrearOtro 
          ? 'btn btn-outline-primary mb-1'
          : 'btn btn-outline-secondary ml-1 mb-1';
        this.$swal({
          title: 'Matrícula ingresada 👍',
          html: 'La matrícula fue creada con éxito! <br>¿Cómo deseas continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Completar ficha de estudiante',
          cancelButtonText,
          customClass: {
            confirmButton: 'btn btn-outline-primary mb-1',
            cancelButton: cancelButtonClass,
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.setMatricula(matricula)
            this.$router.push({
              name: 'matriculas-update',
            })
          } else {
            if (this.configs.chkCrearOtro) {
              this.resetForm()
            } else {
              this.$router.push({
                name: 'matriculas',
              })
            }
            this.configs.loading = false
          }
        })
      } else if (response.status === 'error') {
        this.$swal({
          title: 'Error al crear matrícula',
          text: response.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.configs.loading = false
      }
    },
    validaEstudiante() {
      return new Promise((resolve, reject) => {
        if (this.$refs.tabEstudiante.validarFormulario()) {
          resolve(true);
        } else {
          reject()
        }
      });
    },
    validaApoderado() {
      return new Promise((reject) => {
        if (this.$refs.tabApoderado.validarFormulario()) {
          this.onSubmit()
        } else {
          reject()
        }
      });
    },
    resetForm() {
      this.configs.currentStep = 0;
      this.dataForm = this.dataFormReset;
      this.$refs.tabEstudiante.resetValidations()
      this.$refs.tabApoderado.resetValidations()
      this.$refs.refFormWizard.activateTab(0);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
